import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { LocalStorageService } from '../../services/localStorage.service';
import { TechPartnerFullCard } from '../../models/TechPartnerFullCard.model';
import { Router } from '@angular/router';
import { SelectedPartner } from '../../models/SelectedPartner.model';

@Component({
  selector: 'app-compare-tech-partners',
  templateUrl: './compare-tech-partners.component.html',
  styleUrl: './compare-tech-partners.component.scss',
})
export class CompareTechPartnersComponent {

  constructor(private localStorageService: LocalStorageService, private location: Location, private router: Router) { }

  partners: TechPartnerFullCard[] = [];
  selectedPartners = this.partners;

  isLoading: boolean = true;


  ngOnInit(): void {
    this.getSelectedPartners();
  }

  getSelectedPartners() {
    this.isLoading = true;
    this.sortPartners();
    this.isLoading = false;
  }

  sortPartners() {
    this.partners = this.localStorageService.getSelectedPartners();
    
    this.partners.sort((a, b) => {
      const certDiff = b.certifications.length - a.certifications.length;
  
      if (certDiff !== 0) {
        return certDiff; // Sort by number of certifications in descending order
      }
  
      const profileScoreDiff = b.card.profileScore! - a.card.profileScore!;
  
      if (profileScoreDiff !== 0) {
        return profileScoreDiff; // Sort by profile score in descending order
      }
  
      return Math.random() < 0.5 ? -1 : 1; // Random order if both certifications and profile score are equal
    });
  
    this.selectedPartners = this.partners;
  }

  goBackToSearchResults() {
    this.location.back();
    this.localStorageService.saveSelectedPartners(this.selectedPartners);
  }

  handlePartnerSelection(partner: TechPartnerFullCard, selected: boolean) {
    if (selected && this.selectedPartners.length < this.partners.length) {
      this.selectedPartners.push(partner);
    }
    else if (!selected) {
      this.selectedPartners = this.selectedPartners.filter(p => p !== partner);
    }
  }

  isPartnerSelected(partner: TechPartnerFullCard): boolean {
    return this.partners.includes(partner);
  }

  isCheckboxDisabled(partner: TechPartnerFullCard): boolean {
    return !this.isPartnerSelected(partner) && this.partners.length >= 4;
  }

  areValidPartnersSelected(): boolean {  
    return this.selectedPartners.every(partner => {
      const techPartnerTypeId = partner.card.techPartnerTypeId;
  
      return techPartnerTypeId !== 1;
    });
  }

  onRequestContact() {

    console.log("this.selectedPartners", this.selectedPartners);

    const mappedSelectedPartners: SelectedPartner[] = this.selectedPartners.map(partner => {
      
      
      return {
        companyId: partner.card.companyId,
        id: partner.card.id,
        name: partner.card.companyName,
        techPartnerId: partner.card.id 
      };
    });
    
    this.localStorageService.saveSelectedPartnersToContact(mappedSelectedPartners);
    this.router.navigateByUrl(`/contact`);
  }

}
