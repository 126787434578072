import { AfterContentInit, AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { HeaderFooterService } from './services/header-footer.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class AppComponent implements AfterContentInit{
  headerContent: SafeHtml | null = null;
  footerContent: SafeHtml | null = null;

  private url = 'https://gs1pt.org/header_footer/';

  constructor(private headerFooterService: HeaderFooterService, private http: HttpClient) { }
  

  ngOnInit(): void {
    this.headerFooterService.getHeaderFooter().subscribe((content) => {
      this.headerContent = content.header;
      this.footerContent = content.footer;
    });

    this.http.get(this.url, { responseType: 'text' }).subscribe(response => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(response, 'text/html');

      // Append the scripts and styles
      this.appendScriptsAndStyles(doc);

    let btn = document.getElementsByClassName("cmplz-manage-consent")[0] as HTMLElement
    if(btn){
      btn.style.left = '40px';
      btn.style.right = 'auto';
    }
    });
  }

ngAfterContentInit(): void {
    
  }

  appendScriptsAndStyles(doc: Document): void {

    // Append external stylesheets to head (if any)
    const styleSheets = doc.querySelectorAll('style, link[rel="stylesheet"]');
    styleSheets.forEach(sheet => {
      if (sheet.tagName.toLowerCase() === 'link') {
        const newLink = document.createElement('link') as HTMLLinkElement;;
        newLink.rel = 'stylesheet';
        newLink.href = (sheet as HTMLLinkElement).href;
        document.head.appendChild(newLink);
      } else if (sheet.tagName.toLowerCase() === 'style') {
        const newStyle = document.createElement('style') as HTMLLinkElement;;
        newStyle.textContent = sheet.textContent;
        document.head.appendChild(newStyle);
      }
    });

    // Append scripts to head
    const scripts = doc.querySelectorAll('script');
    scripts.forEach(script => {
      const newScript = document.createElement('script');
      if (script.src) {
        newScript.src = script.src;
        newScript.async = false; // Ensure scripts are executed in order
      } else {
        newScript.textContent = script.textContent;
      }
      document.head.appendChild(newScript);
    });

    
      
  };

}
