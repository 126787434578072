import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { BreadcrumbServiceService } from './breadcrumb-service.service';
import { Subject } from 'rxjs';
import { TechPartnerFullCard } from '../../models/TechPartnerFullCard.model';
import { TechPartnerService } from '../../services/techpartner.service';
import { FilterRequestBody } from '../../models/FilterRequestBody.model';
import { FilterTag } from '../../models/FilterTag.model';
import { FilterService } from '../../models/FilterService.model';
import { FormControl, FormGroup } from '@angular/forms';
import { FiltersService } from '../../services/filter.service';
import { LocalStorageService } from '../../services/localStorage.service';

interface Filter {
  name: string;
  code: number;
  tagTypeId?: number;
}

@Component({
  selector: 'app-app-breadcrumb',
  templateUrl: './app-breadcrumb.component.html',
  styleUrls: ['./app-breadcrumb.component.scss'],
})
export class AppBreadcrumbComponent implements OnInit, OnDestroy {
  items: MenuItem[] = [];
  showSearch: boolean = false;

  constructor(
    private breadcrumbService: BreadcrumbServiceService,
    private router: Router,
    private filterService: FiltersService
  ) {}
 

  private unsubscribe$: Subject<void> = new Subject<void>();
  techPartners: TechPartnerFullCard[] = [];
  techPartnerService = inject(TechPartnerService);

  isLoading: boolean = false;

  searchQuery!: string;
  filters: FilterRequestBody = {};

  sectors!: Filter[];
  selectedSectors: Filter[] = [];
  passedSectors: FilterTag[] = [];

  services!: Filter[];
  selectedServices: Filter[] = [];
  passedServices: FilterTag[] = [];

  regions!: Filter[];
  selectedRegions: Filter[] = [];
  passedRegions: FilterTag[] = [];

  myGroup!: FormGroup;

  displayModal: boolean = false;
  modalMessages: string[] = [];
  modalDelay: number = 0;

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.myGroup = new FormGroup({
      sector: new FormControl(''),
      service: new FormControl(''),
      region: new FormControl(''),
    });

    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.items = this.breadcrumbService.getBreadcrumbs();

        // Set the isCurrent value of the existing breadcrumbs to false except the last item
        this.items.forEach((item, index) => {
          if (index !== this.items.length - 1) {
            item['isCurrent'] = false;
          } else {
            item['isCurrent'] = true; // Setting the last breadcrumb as current
          }
        });

        const currentRoute = this.router.url;
        console.log('Current route:', currentRoute);
        this.showSearch =
          currentRoute === '/home' || currentRoute === '/partners' || currentRoute === '/header';

        if (this.showSearch) {
          this.resetFormControls();
        }
      });

    this.loadFiltersList();
  }

  navigationAll() {
    this.router.navigateByUrl('/partners');
  }

  applyFiltersAndNavigate() {
    this.isLoading = true;
    this.selectedFilter();
    this.loadTechPartnersFilteredList()
      .then(() => {
        if (this.techPartners.length === 0) {
          setTimeout(() => {
            this.displayModal = true;
            this.progressivelyReduceFilters();
            // Reduce the filters
          }, this.modalDelay);
        } else {
          this.displayModal = false;
          this.finalizeNavigation();
        }
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  progressivelyReduceFilters() {
    this.modalDelay = 500;
    const reduceFiltersAndSearch = () => {
      this.loadTechPartnersFilteredList()
        .then(() => {
          if (this.techPartners.length === 0) {
            this.modalMessages.push(this.getModalMessage()); // Push the next message
            this.modalDelay += 500; // Increment the delay
            this.selectedFilter(true);
            setTimeout(() => {
              reduceFiltersAndSearch(); // Recursively call again
            }, this.modalDelay);
          } else {
            this.displayModal = false;
            this.finalizeNavigation();
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    };
    reduceFiltersAndSearch();
  }

  finalizeNavigation() {
    this.displayModal = false;
    this.modalMessages = [];
    this.modalDelay = 0; // Reset the delay to the initial value
    this.isLoading = false;
    this.router.navigateByUrl('/filtered-partners');
  }

  loadFiltersList() {
    this.techPartnerService
      .getFilters()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          this.services = response.value.filterTags
            .filter((tag: FilterTag) => tag.tagTypeId === 2) // 2 is the id of services in the DD_TagType
            .map((tag: FilterTag) => ({
              name: tag.descriptionTag,
              code: tag.tagId,
              tagTypeId: tag.tagTypeId,
            }));

          this.regions = response.value.filterTags
            .filter((tag: FilterTag) => tag.tagTypeId === 3) // 3 is the id of regions in the DD_TagType
            .map((tag: FilterTag) => ({
              name: tag.descriptionTag,
              code: tag.tagId,
              tagTypeId: tag.tagTypeId,
            }));

          this.sectors = response.value.filterTags
            .filter((tag: FilterTag) => tag.tagTypeId === 4) // 4 is the id of sectors in the DD_TagType
            .map((tag: FilterTag) => ({
              name: tag.descriptionTag,
              code: tag.tagId,
              tagTypeId: tag.tagTypeId,
            }));
        },
      });
  }

  selectedFilter(reduceFilters: boolean = false) {
    const selectedSector = this.myGroup.get('sector')?.value;
    const selectedService = this.myGroup.get('service')?.value;
    const selectedRegion = this.myGroup.get('region')?.value;

    if (reduceFilters) {
      // Check and clear filters in the desired order: regions, sectors, services
      if (this.selectedRegions.length > 0) {
        this.selectedRegions = [];
      } else if (this.selectedSectors.length > 0) {
        this.selectedSectors = [];
      } else if (this.selectedServices.length > 0) {
        this.selectedServices = [];
      }
    } else {
      this.selectedSectors = selectedSector ? [selectedSector] : [];
      this.selectedServices = selectedService ? [selectedService] : [];
      this.selectedRegions = selectedRegion ? [selectedRegion] : [];
    }

    this.passedServices = this.selectedServices.map((filter) => ({
      tagTypeId: filter.tagTypeId,
      tagId: filter.code,
      descriptionTag: filter.name,
    }));

    this.passedRegions = this.selectedRegions.map((filter) => ({
      tagTypeId: filter.tagTypeId,
      tagId: filter.code,
      descriptionTag: filter.name,
    }));

    this.passedSectors = this.selectedSectors.map((filter) => ({
      tagTypeId: filter.tagTypeId,
      tagId: filter.code,
      descriptionTag: filter.name,
    }));

    const combinedFilterTags: FilterTag[] = [
      ...this.passedServices,
      ...this.passedRegions,
      ...this.passedSectors,
    ];

    this.filters = {
      filterTags: combinedFilterTags,
    };

    this.filterService.setFilters(this.filters);
  }

  loadTechPartnersFilteredList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.techPartnerService
        .loadTechPartnersFilteredList(
          this.filters,
          0, // Initial page
          10, // Page size
          this.searchQuery
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            this.techPartners = response.data;
            resolve();
          },
          error: (error) => {
            console.error('Error loading tech partners:', error);
            reject(error);
          },
        });
    });
  }

  resetFormControls() {
    this.myGroup.reset();
  }

  getModalMessage(): string {
    if (this.selectedRegions.length > 0) {
      return (
        'Não foram encontrados resultados na região ' +
        this.selectedRegions[0].name +
        ', a pesquisa foi alargada.'
      );
    } else if (this.selectedSectors.length > 0) {
      return (
        'Não foram encontrados resultados para o setor ' +
        this.selectedSectors[0].name +
        ', a pesquisa foi alargada.'
      );
    } else {
      return 'Não foram encontrados resultados, filtros foram removidos.';
    }
  }
}
