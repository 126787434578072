<div class="pageWrapper">
  <section class="filtersWrapper" *ngIf="displayTechPartners.length > 0 || !isLoading">
    <div class="sticky">
      <span class="p-input-icon-left search-wrapper">
        <i class="bi bi-search icon"></i>
        <input id="search" type="text" pInputText placeholder="Pesquisa" (input)="searchFilter($event)"
          [(ngModel)]="searchQuery" />
        <i class="bi bi-x-lg icon clear-icon" *ngIf="searchQuery" (click)="clearSearch()"></i>
      </span>
      <div class="filtersHeader">
        <h4>Refinar Resultados</h4>
      </div>
      <p-accordion class="accordionWrapper" expandIcon="bi bi-chevron-down" collapseIcon="bi bi-chevron-up">
        <p-accordionTab class="accordion-scroll">
          <ng-template pTemplate="header">
            <span class="flex align-items-center">
              <span class="white-space-nowrap"> Certificações </span>

              <span *ngIf="selectedCertifications.length > 0" class="filter-count-circle">
                {{ selectedCertifications.length }}
              </span>
            </span>
          </ng-template>
          <div class="accordion-scroll">
          <div *ngFor="let certification of certifications" [ngClass]="{
              'enabling-class': isSelectedCertification(certification)
            }">
            <p-checkbox [(ngModel)]="selectedCertifications" [label]="certification.name" name="certifications"
              [value]="certification" (onChange)="
                toggleFilterSelection('certifications', certification, $event)
              "></p-checkbox>
          </div>
        </div>
        <div class="flex align-items-center" style="padding: 12px 18px;" *ngIf="certifications?.length === 0">
         <span class="white-space-nowrap">Sem certificações</span> 
        </div>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <span class="flex align-items-center">
              <span class="white-space-nowrap"> Serviços </span>
              <span *ngIf="selectedServices.length > 0" class="filter-count-circle">
                {{ selectedServices.length }}
              </span>
            </span>
          </ng-template>
          <div class="accordion-scroll">
          <div *ngFor="let service of services" [ngClass]="{ 'enabling-class': isSelectedServices(service) }">
            <p-checkbox [(ngModel)]="selectedServices" [label]="service.name" name="services" [value]="service"
              (onChange)="toggleFilterSelection('services', service, $event)"></p-checkbox>
          </div>
        </div>
        <div class="flex align-items-center" style="padding: 12px 18px;" *ngIf="services?.length === 0">
          <span class="white-space-nowrap">Sem Serviços</span> 
         </div>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <span class="flex align-items-center">
              <span class="white-space-nowrap"> Região </span>
              <span *ngIf="selectedRegions.length > 0" class="filter-count-circle">
                {{ selectedRegions.length }}
              </span>
            </span>
          </ng-template>
          <div class="accordion-scroll">
          <div *ngFor="let region of regions" [ngClass]="{ 'enabling-class': isSelectedRegions(region) }">
            <p-checkbox [(ngModel)]="selectedRegions" [label]="region.name" name="regions" [value]="region"
              (onChange)="toggleFilterSelection('regions', region, $event)"></p-checkbox>
          </div>
        </div>
        <div class="flex align-items-center" style="padding: 12px 18px;" *ngIf="regions?.length === 0">
          <span class="white-space-nowrap">Sem Regiões</span> 
         </div>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <span class="flex align-items-center" [ngClass]="{ 'has-filter': selectedSectors.length > 0 }">
              <span class="white-space-nowrap"> Sectores </span>
              <span *ngIf="selectedSectors.length > 0" class="filter-count-circle">
                {{ selectedSectors.length }}
              </span>
            </span>
          </ng-template>
          <div class="accordion-scroll">
          <div *ngFor="let sector of sectors" [ngClass]="{ 'enabling-class': isSelectedSectors(sector) }">
            <p-checkbox [(ngModel)]="selectedSectors" [label]="sector.name" name="sectors" [value]="sector"
              (onChange)="toggleFilterSelection('sectors', sector, $event)"></p-checkbox>
          </div>
        </div>
        <div class="flex align-items-center" style="padding: 12px 18px;" *ngIf="sectors?.length === 0">
          <span class="white-space-nowrap">Sem Sectores</span> 
         </div>
        </p-accordionTab>
      </p-accordion>
      <button pButton class="btn btn-link-grey" (click)="clearFiltersSelection()">
        Limpar selecção
      </button>
    </div>
  </section>
  <section class="contentWrapper" infinite-scroll [infiniteScrollDistance]="5" (scrolled)="onScrollDown()">
    <div class="grid justify-content-center">
      <ng-container>
        <ng-container *ngFor="let partner of this.displayTechPartners; let i = index">
          <div class="partner-card-container">
            <div class="col justify-content-center">
              <app-app-partner-card class="cardListItem" [partner]="partner"
                (selectPartner)="handlePartnerSelection(partner, $event)" [isSelected]="isPartnerSelected(partner)"
                [isCheckboxDisabled]="isCheckboxDisabled(partner)">
              </app-app-partner-card>
            </div>
          </div>
          <hr *ngIf="(i + 1) % 2 === 0 && i + 1 < displayTechPartners.length" class="row-divider" />
        </ng-container>
        <app-app-loader-spinner [isLoading]="isLoading"></app-app-loader-spinner>
      </ng-container>
    </div>
    <div *ngIf="!isLoading && displayTechPartners.length === 0" class="no-partners-message">
      Não há parceiros disponíveis.
    </div>
  </section>
</div>



<app-app-partners-selection-footer [partners]="selectedPartners" (clearSelection)="clearSelection()" [@fadeInOut]
  *ngIf="selectedPartners.length"></app-app-partners-selection-footer>

<div class="card flex justify-content-center dialogContainer">
  <p-dialog [(visible)]="filtersConfirmation" [modal]="true" position="top" closeOnEscape="false" closable="false"
    draggable="false" showHeader="false">
    <div class="flex flex-column filtersDialog">
      <div>
        <h4>Alteração dos Filtros</h4>
        <p>
          A alteração dos filtros vai resultar na exclusão dos seguintes
          parceiros da selecção:
        </p>
      </div>
      <div class="checkbox-container">
        <ng-container *ngFor="let partner of confirmPartners; let i = index">
          <div class="partner-container">
            <span>{{ partner.card.companyName }}</span>
            <p-checkbox label="Selecionar" [value]="partner" [(ngModel)]="selectedConfirmPartners"
              (onChange)="toggleConfirmedPartners(partner)"></p-checkbox>
          </div>
          <hr *ngIf="i < confirmPartners.length - 1" />
        </ng-container>
      </div>
      <div class="flex align-items-center gap-2 justify-content-between">
        <p>Confirme os parceiros que pretende continuar a visualizar</p>
        <button pButton class="btn btn-primary" (click)="submitPartners()">
          Submeter
        </button>
      </div>
    </div>
  </p-dialog>
</div>