import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalStorageService } from '../../services/localStorage.service';
import { Router } from '@angular/router';
import { TechPartnerFullCard } from '../../models/TechPartnerFullCard.model';
import { SelectedPartner } from '../../models/SelectedPartner.model';

@Component({
  selector: 'app-app-partners-selection-footer',
  templateUrl: './app-partners-selection-footer.component.html',
  styleUrls: ['./app-partners-selection-footer.component.scss']
})
export class AppPartnersSelectionFooterComponent {

  @Input() partners: TechPartnerFullCard[] = [];
  @Output() clearSelection = new EventEmitter<void>();

  constructor(private localStorageService: LocalStorageService, private router: Router) {}


  onClearSelection() {
    this.clearSelection.emit();
  }

  onRequestContact() {
    this.savePartnersToLocalStorage();
    this.router.navigateByUrl(`/contact`);
  }

  onCompareTechPartners() {
    this.savePartnersToLocalStorage();
    this.router.navigateByUrl(`/comparator`);
  }

  savePartnersToLocalStorage(){
    this.localStorageService.saveSelectedPartners(this.partners);

    const mappedSelectedPartners: SelectedPartner[] = this.partners.map(partner => {
      return {
        companyId: partner.card.companyId,
        id: partner.card.id,
        name: partner.card.companyName,
        techPartnerId: partner.card.id
      };
    });
    
    this.localStorageService.saveSelectedPartnersToContact(mappedSelectedPartners);
  }

  areValidPartnersSelected(): boolean {  
    return this.partners.every(partner => {
      const techPartnerTypeId = partner.card.techPartnerTypeId;
      
      return techPartnerTypeId !== 1;
    });
  }
}
