import { Injectable } from '@angular/core';
import { TechPartnerFullCard } from '../models/TechPartnerFullCard.model';
import { TechPartnerFullProfile } from '../models/TechPartnerFullProfile';
import { SelectedPartner } from '../models/SelectedPartner.model';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private storageKeyToGetSelectedPartners = 'selectedPartners';
    private storageKeyToGetPartnersToContact = 'selectedPartnersToContact';
    private storageKeyToGetPartnerProfile= 'selectedPartnerProfile';


    saveSelectedPartners(partners: TechPartnerFullCard[]): void {
        localStorage.setItem(this.storageKeyToGetSelectedPartners, JSON.stringify(partners));
    }

    getSelectedPartners(): TechPartnerFullCard[] {
        const partnersJson = localStorage.getItem(this.storageKeyToGetSelectedPartners);
        return partnersJson ? JSON.parse(partnersJson) : [];
    }

    clearSelectedPartners(): void {
        localStorage.removeItem(this.storageKeyToGetSelectedPartners);
    }

    saveSelectedPartnersToContact(partners: SelectedPartner[]): void {
        localStorage.setItem(this.storageKeyToGetPartnersToContact, JSON.stringify(partners));
    }

    getSelectedPartnersToContact(): SelectedPartner[] {
        const partnersJson = localStorage.getItem(this.storageKeyToGetPartnersToContact);
        return partnersJson ? JSON.parse(partnersJson) : [];
    }

    clearSelectedPartnersToContact(): void {
        localStorage.removeItem(this.storageKeyToGetPartnersToContact);
    }

}
