import { HttpClient } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { HeaderFooterService } from '../../services/header-footer.service';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class ShellComponent {

  headerContent: SafeHtml | null = null;
  footerContent: SafeHtml | null = null;
  destroy = new Subject<null>()
  
  
  constructor(private headerFooterService: HeaderFooterService, private http: HttpClient) { }

  ngOnInit(): void {
    this.loadHeaderFooter();
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.unsubscribe()
  }

  loadHeaderFooter() {
    this.headerFooterService.getHeaderFooter()
      .pipe(
        switchMap(content => {
          return this.headerFooterService.getStyles()
            .pipe(tap(response => {
              // this disables wordpress errors from showing in the console
              window.onerror = () => true;
              const parser = new DOMParser();
              const doc = parser.parseFromString(response, 'text/html');
              //load html
              this.headerContent = content.header
              this.footerContent = content.footer
              // Append the scripts and styles
              this.appendScriptsAndStyles(doc);
              let btn = document.getElementsByClassName("cmplz-manage-consent")[0] as HTMLElement
              if (btn) {
                btn.style.left = '40px';
                btn.style.right = 'auto';
              }
              window.onerror = () => false;
              
            }))
        }),
        takeUntil(this.destroy)
      ).subscribe();
  }

  ngAfterContentInit(): void {
    
  }
  
  appendScriptsAndStyles(doc: Document): void {

    // Append external stylesheets to head (if any)
    const styleSheets = doc.querySelectorAll('style, link[rel="stylesheet"]');
    styleSheets.forEach(sheet => {
      if (sheet.tagName.toLowerCase() === 'link') {
        const newLink = document.createElement('link') as HTMLLinkElement;;
        newLink.rel = 'stylesheet';
        newLink.href = (sheet as HTMLLinkElement).href;
        document.head.appendChild(newLink);
      } else if (sheet.tagName.toLowerCase() === 'style') {
        const newStyle = document.createElement('style') as HTMLLinkElement;;
        newStyle.textContent = sheet.textContent;
        document.head.appendChild(newStyle);
      }
    });

    // Append scripts to head
    const scripts = doc.querySelectorAll('script');
    scripts.forEach(script => {
      const newScript = document.createElement('script');
      if (script.src) {
        newScript.src = script.src;
        newScript.async = false; // Ensure scripts are executed in order
      } else {
        newScript.textContent = script.textContent;
      }
      document.head.appendChild(newScript);
    });

    
      
  };

}
