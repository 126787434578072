import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ContactTechPartnersComponent } from './pages/contact-tech-partners/contact-tech-partners.component';
import { CompareTechPartnersComponent } from './pages/compare-tech-partners/compare-tech-partners.component';
import { TechPartnerListComponent } from './pages/tech-partner-list/tech-partner-list.component';
import { TechPartnerListAllComponent } from './pages/tech-partner-list-all/tech-partner-list-all.component';
import { TechPartnerProfileComponent } from './pages/tech-partner-profile/tech-partner-profile.component';
import { ShellComponent } from './shell/shell/shell.component';
import { AppBreadcrumbComponent } from './components/app-breadcrumb/app-breadcrumb.component';

const routes: Routes = [
  {
    path: 'home',
    title: 'Diretório de Parceiros',
    component: HomeComponent,
    data: { breadcrumb: 'Diretório de Parceiros' },
  },
  {
    path: 'contact',
    title: 'Pedido de Contacto',
    component: ContactTechPartnersComponent,
    data: { breadcrumb: 'Pedido de Contacto' },
  },
  {
    path: 'comparator',
    title: 'Comparador de Parceiros Tecnológicos',
    component: CompareTechPartnersComponent,
    data: { breadcrumb: 'Comparador de Parceiros' },
  },
  {
    path: 'filtered-partners',
    title: 'Parceiros Tecnológicos',
    component: TechPartnerListComponent,
    data: { breadcrumb: 'Resultados de Pesquisa' },
  },
  {
    path: 'partners',
    title: 'Parceiros Tecnológicos',
    component: TechPartnerListAllComponent,
    data: { breadcrumb: 'Parceiros Tecnológicos' },
  },
  {
    path: 'partner-profile/:id',
    title: 'Perfil de Parceiro Tecnológico',
    component: TechPartnerProfileComponent,
    data: { breadcrumb: 'Perfil de Parceiro Tecnológico' },
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

const appRoutes : Routes = [
  {
    path: '',
    component: ShellComponent,
    children: routes
  },
  {
    path: "header",
    component: AppBreadcrumbComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
