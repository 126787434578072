import { Component, Input } from '@angular/core';
import { FaqService } from '../../services/faq.service';
import { Subject, finalize, takeUntil } from 'rxjs';
import { FaqResponse } from '../../models/FaqResponse.model';

@Component({
  selector: 'app-app-faq-card',
  templateUrl: './app-faq-card.component.html',
  styleUrl: './app-faq-card.component.scss'
})
export class AppFaqCardComponent {

  constructor(private faqService: FaqService) { }

  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input() faq!: FaqResponse;

  imageFaq: string = "";

  ngOnInit(): void {
    this.getImage();
  }

  getImage() {
    this.faqService.getImageUrl(this.faq.featured_media).subscribe(
      data => {
        this.imageFaq = data.link;
      },
      error => console.error('Error fetching the image URL:', error)
    );
  }

  removeTag(str: string) {
    return str.replace(/<\/?p>/g, "");
  }

  redirectToFaq(){
    window.open(this.faq.link, "_blank");
  }
}
