import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { LocalStorageService } from '../../services/localStorage.service';
import { Subject, debounceTime, finalize, map, takeUntil } from 'rxjs';
import { TechPartnerFullCard } from '../../models/TechPartnerFullCard.model';
import { TechPartnerService } from '../../services/techpartner.service';
import { FilterRequestBody } from '../../models/FilterRequestBody.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { FilterTag } from '../../models/FilterTag.model';
import { FilterCertification } from '../../models/FilterCertification.model';
import { FilterService } from '../../models/FilterService.model';
import { FiltersService } from '../../services/filter.service';

interface Filter {
  name: string;
  code: number;
  tagTypeId?: number;
}

@Component({
  selector: 'app-tech-partner-list',
  templateUrl: './tech-partner-list.component.html',
  styleUrl: './tech-partner-list.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('200ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class TechPartnerListComponent implements OnInit, OnDestroy {
  constructor(
    private localStorageService: LocalStorageService,
    private cd: ChangeDetectorRef,
    private filterService: FiltersService
  ) { }

  private unsubscribe$: Subject<void> = new Subject<void>();
  selectedPartners: TechPartnerFullCard[] = [];
  techPartners: Map<number, TechPartnerFullCard> = new Map();
  displayTechPartners: TechPartnerFullCard[] = [];
  techPartnerService = inject(TechPartnerService);

  isLoading: boolean = true;
  isLoadingMore: boolean = false;

  certifications!: Filter[];
  selectedCertifications: Filter[] = [];
  passedCertifications!: FilterCertification[];

  services!: Filter[];
  selectedServices: Filter[] = [];
  passedServices!: FilterTag[];

  regions!: Filter[];
  selectedRegions: Filter[] = [];
  passedRegions!: FilterTag[];

  sectors!: Filter[];
  selectedSectors: Filter[] = [];
  passedSectors!: FilterTag[];

  searchQuery!: string;
  filters: FilterRequestBody = {};
  currentPage = signal(0);
  size: number = 10;

  filtersConfirmation: boolean = false;
  filtersDialogPosition = 'top';

  confirmPartners: TechPartnerFullCard[] = [];
  selectedConfirmPartners: TechPartnerFullCard[] = [];
  private searchSubject: Subject<string> = new Subject<string>();

  ngOnInit(): void {
    this.getSelectedPartners(); // Ensure selected partners are retrieved initially
    this.filters = this.filterService.getFilters();
    this.loadFiltersList();
    this.loadTechPartnersFilteredList();

    this.searchSubject.pipe(
      debounceTime(300) // debounce time of 300 milliseconds
    ).subscribe(() => {
      this.currentPage.set(0);
      this.loadTechPartnersFilteredList();
    });

  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePartnerSelection(partner: TechPartnerFullCard, selected: boolean) {
    if (selected && this.selectedPartners.length < 4) {
      this.selectedPartners.push(partner);
    } else if (!selected) {
      this.selectedPartners = this.selectedPartners.filter(
        (p) => p.card.id !== partner.card.id
      );
    }
    this.localStorageService.saveSelectedPartners(this.selectedPartners);
  }

  isPartnerSelected(partner: TechPartnerFullCard): boolean {
    return this.selectedPartners.some((p) => p.card.id === partner.card.id);
  }

  isCheckboxDisabled(partner: TechPartnerFullCard): boolean {
    return (
      !this.isPartnerSelected(partner) && this.selectedPartners.length >= 4
    );
  }

  clearSelection() {
    this.selectedPartners = [];
    this.localStorageService.clearSelectedPartners();
    this.localStorageService.clearSelectedPartnersToContact();

    // Reset the current page
    this.currentPage.set(0);

    //Scroll to the top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    this.selectedFilter();
  }

  searchFilter(event: any) {
    const word = (event.target as HTMLInputElement).value;
    this.searchSubject.next(word);
  }

  clearSearch() {
    this.searchQuery = ''; //sets the search query to empty
    this.currentPage.set(0);
    this.loadTechPartnersFilteredList();
  }

  loadFiltersList() {
    this.techPartnerService
      .getFilters()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          // Maping of the response values to the filter structure
          this.certifications = response.value.filterCertifications.map(
            (cert: FilterCertification) => ({
              name: cert.certificationDescription,
              code: cert.certificationId,
            })
          );

          this.services = response.value.filterTags
            .filter((tag: FilterTag) => tag.tagTypeId === 2) // 2 is the id of services in the DD_TagType
            .map((tag: FilterTag) => ({
              name: tag.descriptionTag,
              code: tag.tagId,
              tagTypeId: tag.tagTypeId,
            }));

          this.regions = response.value.filterTags
            .filter((tag: FilterTag) => tag.tagTypeId === 3) // 3 is the id of regions in the DD_TagType
            .map((tag: FilterTag) => ({
              name: tag.descriptionTag,
              code: tag.tagId,
              tagTypeId: tag.tagTypeId,
            }));

          this.sectors = response.value.filterTags
            .filter((tag: FilterTag) => tag.tagTypeId === 4) // 4 is the id of sectors in the DD_TagType
            .map((tag: FilterTag) => ({
              name: tag.descriptionTag,
              code: tag.tagId,
              tagTypeId: tag.tagTypeId,
            }));

          this.initializeSelectedFilters();
        },
      });
  }

  initializeSelectedFilters() {
    this.selectedCertifications = this.filters?.filterCertifications?.map((cert: any) => ({
      name: cert.certificationDescription,
      code: cert.certificationId,
    })) ?? [];


    this.selectedServices = this.filters?.filterTags
      ?.filter((tag) => tag.tagTypeId === 2)
      .map((tag: any) => ({
        name: tag.descriptionTag,
        code: tag.tagId,
        tagTypeId: tag.tagTypeId,
      })) ?? [];

    this.selectedRegions = this.filters?.filterTags
      ?.filter((tag) => tag.tagTypeId === 3)
      .map((tag: any) => ({
        name: tag.descriptionTag,
        code: tag.tagId,
        tagTypeId: tag.tagTypeId,
      })) ?? [];

    this.selectedSectors = this.filters?.filterTags
      ?.filter((tag) => tag.tagTypeId === 4)
      .map((tag: any) => ({
        name: tag.descriptionTag,
        code: tag.tagId,
        tagTypeId: tag.tagTypeId,
      })) ?? [];

  }

  clearFiltersSelection() {
    this.selectedCertifications = [];
    this.selectedServices = [];
    this.selectedRegions = [];
    this.selectedSectors = [];

    this.passedCertifications = [];
    this.passedServices = [];
    this.passedRegions = [];
    this.passedSectors = [];

    this.filters = {};

    // Reset the current page
    this.currentPage.set(0);

    //Scroll to the top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    this.filterService.setFilters(null);

    this.loadTechPartnersFilteredList();
  }

  // Methods to be able to add especific css format properties
  isSelectedCertification(certification: Filter): boolean {
    return this.selectedCertifications.some(
      (c) => c.code === certification.code
    );
  }

  isSelectedServices(service: Filter): boolean {
    return this.selectedServices.some((s) => s.code === service.code);
  }

  isSelectedRegions(region: Filter): boolean {
    return this.selectedRegions.some((r) => r.code === region.code);
  }

  isSelectedSectors(sector: Filter): boolean {
    return this.selectedSectors.some((s) => s.code === sector.code);
  }

  selectedFilter() {
    // Maping of the stored values to the filter structure
    if (this.selectedCertifications)
      this.passedCertifications = this.selectedCertifications.map((filter) => {
        return {
          certificationId: filter.code,
          certificationDescription: filter.name,
        };
      });

    if (this.selectedServices)
      this.passedServices = this.selectedServices.map((filter) => {
        return {
          tagTypeId: filter.tagTypeId,
          tagId: filter.code,
          descriptionTag: filter.name,
        };
      });

    if (this.selectedRegions)
      this.passedRegions = this.selectedRegions.map((filter) => {
        return {
          tagTypeId: filter.tagTypeId,
          tagId: filter.code,
          descriptionTag: filter.name,
        };
      });

    if (this.selectedSectors)
      this.passedSectors = this.selectedSectors.map((filter) => {
        return {
          tagTypeId: filter.tagTypeId,
          tagId: filter.code,
          descriptionTag: filter.name,
        };
      });

    // Combining passedServices, passedRegions and passedSectors into a single array
    const combinedFilterTags: FilterTag[] = [
      ...this.passedServices,
      ...this.passedRegions,
      ...this.passedSectors,
    ];

    this.filters = {
      filterTags: combinedFilterTags,
      filterCertifications: this.passedCertifications,
    };

    this.isLoading = true;
    // Load the tech partners based on the new filters and check if any selected partner will be removed
    this.techPartnerService
      .loadTechPartnersFilteredList(
        this.filters,
        this.currentPage(),
        this.size,
        this.searchQuery
      )
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (response) => {
          const newTechPartners = response.data;

          // Find selected partners that will be removed by the new filters
          this.confirmPartners = this.selectedPartners.filter(
            (selectedPartner) =>
              !newTechPartners.some(
                (techPartner) => techPartner.card.id === selectedPartner.card.id
              )
          );

          this.selectedConfirmPartners = this.confirmPartners;

          // Set filtersConfirmation to true if any selected partner will be removed and if there´s active filters
          if (
            combinedFilterTags.length !== 0 ||
            this.passedCertifications.length !== 0
          )
            this.filtersConfirmation = this.confirmPartners.length > 0;

          this.techPartners.clear()
          // Update the tech partners list with the new filtered data
          newTechPartners.forEach(item => {
            if(!this.techPartners.has(item.card.id)){
              this.techPartners.set(item.card.id, item);
            }
          })

          this.displayTechPartners = [...this.techPartners.values()];

          // Reset the current page
          this.currentPage.set(0);
          this.isLoading = false;
        },
        error: (error) => {
          console.error('Error loading tech partners:', error);
          this.isLoading = false;
        },
      });
  }

  loadTechPartnersFilteredList() {
    this.isLoading = true;
    this.techPartnerService
      .loadTechPartnersFilteredList(
        this.filters,
        this.currentPage(),
        this.size,
        this.searchQuery
      )
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (response) => {
          this.techPartners = new Map();
          response.data.forEach(item => {
            if(!this.techPartners.has(item.card.id)){
              this.techPartners.set(item.card.id, item);
            }
          });
          this.displayTechPartners = [...this.techPartners.values()];
          this.isLoading = false;
        },
        error: (error) => {
          console.error('Error loading tech partners:', error);
          this.isLoading = false; // Ensure spinner is hidden if there's an error
        },
      });
  }

  loadMoreValues() {
    this.isLoading = true;
    this.techPartnerService
      .loadTechPartnersFilteredList(
        this.filters,
        this.currentPage(),
        this.size,
        this.searchQuery
      )
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (response) => {
          response.data.forEach(item => {
            if(!this.techPartners.has(item.card.id)){
              this.techPartners.set(item.card.id, item);
            }
          })
          this.displayTechPartners = [...this.techPartners.values()];
          this.isLoading = false;
        },
        error: (error) => {
          console.error('Error loading tech partners:', error);
          this.isLoading = false; // Ensure spinner is hidden if there's an error
        },
      });
  }

  getSelectedPartners() {
    this.selectedPartners = this.localStorageService.getSelectedPartners();
  }

  onScrollDown() {
    if (this.isLoading) {
      return;
    }
    this.loadMoreValues()
  }

  submitPartners() {
    // Iterate over each partner in selectedConfirmPartners
    const items = [...this.techPartners.values()];
    this.selectedConfirmPartners.forEach((partner) => {
      // Check if partner is already in techPartners
      const index = items.findIndex(
        (tp) => tp.card.id === partner.card.id
      );
  
      // If partner is not already in techPartners, add it in the correct order
      if (index === -1) {
        // Find the correct position to insert based on number of certifications in descending order
        // In case of same number of certifications, sort by completeness of the profile
        // In case of same number of certifications and profile completeness, sort by random order
        let insertIndex = items.findIndex((tp) => {
          const certDiff = tp.certifications.length - partner.certifications.length;
          if (certDiff < 0) {
            return true;
          } else if (certDiff === 0) {
            const profileScoreDiff = tp.card.profileScore! - partner.card.profileScore!;
            if (profileScoreDiff < 0) {
              return true;
            } else if (profileScoreDiff === 0) {
              return Math.random() < 0.5; // random position if both certifications and profile completeness are equal
            }
            return false;
          }
          return false;
        });
  
        if (insertIndex === -1) {
          insertIndex = items.length; // Insert at the end if not found
        }
  
        items.splice(insertIndex, 0, partner); // Insert partner at insertIndex
        this.techPartners = new Map();
        items.forEach(item => {
          if(!this.techPartners.has(item.card.id)){
            this.techPartners.set(item.card.id, item);
          }
        })
        this.displayTechPartners = [...this.techPartners.values()];
      }
    });
  
    // Clear confirmPartners and selectedConfirmPartners after submitting
    this.confirmPartners = [];
    this.selectedConfirmPartners = [];
  
    // Reset filtersConfirmation after submitting
    this.filtersConfirmation = false;
  }

  toggleFilterSelection(type: string, filter: Filter, event: any) {
    const isChecked = event.checked;

    // Reset the current page
    this.currentPage.set(0);

    switch (type) {
      case 'certifications':
        if (isChecked.length == 0) {
          this.selectedCertifications = this.selectedCertifications.filter(
            (c) => c.code !== filter.code
          );
        }
        break;
      case 'services':
        if (isChecked.length == 0) {
          this.selectedServices = this.selectedServices.filter(
            (s) => s.code !== filter.code
          );
        }
        break;
      case 'regions':
        if (isChecked.length == 0) {
          this.selectedRegions = this.selectedRegions.filter(
            (r) => r.code !== filter.code
          );
        }
        break;
      case 'sectors':
        if (isChecked.length == 0) {
          this.selectedSectors = this.selectedSectors.filter(
            (s) => s.code !== filter.code
          );
        }
        break;
      default:
        break;
    }

    this.selectedFilter();
  }

  toggleConfirmedPartners(partner: TechPartnerFullCard) {
    const selectedPartners = this.localStorageService.getSelectedPartners();
    const index = selectedPartners.findIndex(
      (p) => p.card.id === partner.card.id
    );

    if (index > -1) {
      selectedPartners.splice(index, 1);
      this.selectedPartners.splice(index, 1);
    } else {
      selectedPartners.push(partner);
      this.selectedPartners.push(partner);
    }

    this.localStorageService.saveSelectedPartners(selectedPartners);
    this.selectedConfirmPartners = selectedPartners;
  }
}
