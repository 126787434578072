<section>
  <div class="container">
    <p-button
      label="Limpar Seleção"
      styleClass="btn btn-link-blue left"
      (onClick)="onClearSelection()"
    ></p-button>

    <div class="right-buttons">
      <p-button
        [disabled]="partners.length < 2"
        label="Comparar Parceiros ({{ partners.length }}/4)"
        styleClass="btn btn-primary button-arrow"
        (onClick)="onCompareTechPartners()"
      ></p-button>

      <p-button
        label="Pedir Contactos ({{ partners.length }}/4)"
        styleClass="btn btn-secondary button-arrow"
        (onClick)="onRequestContact()"
        [disabled]="!areValidPartnersSelected()"
      ></p-button>
    </div>
  </div>
</section>
