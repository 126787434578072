import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, finalize, takeUntil } from 'rxjs';
import { RequestContactService } from '../../services/request-contact.service';
import { Location } from '@angular/common';
import { LocalStorageService } from '../../services/localStorage.service';
import { Router } from '@angular/router';
import { SelectedPartner } from '../../models/SelectedPartner.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';

interface Types {
  code: number;
  label: string;
}

interface ContactType {
  id: number;
  contactType: string;
  isActive: boolean;
  createdOn: string;
  createdBy: string;
}

@Component({
  selector: 'app-contact-tech-partners',
  templateUrl: './contact-tech-partners.component.html',
  styleUrl: './contact-tech-partners.component.scss',
})
export class ContactTechPartnersComponent implements OnInit, OnDestroy {
  selectedPartners!: SelectedPartner[];
  partners: SelectedPartner[] = [];
  types: Types[] | undefined;

  requestContactForm!: FormGroup;

  destroy$ = new Subject<void>();

  isFormSubmitted = false;
  isLoading = false; // Initialize isLoading as false

  constructor(
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private requestContactService: RequestContactService,
    private location: Location,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    this.getPartners();

    this.requestContactForm = this.formBuilder.group({
      name: [''],
      surname: [''],
      email: ['', [Validators.required, Validators.email]],
      organizationName: ['', Validators.required],
      contactType: ['', Validators.required],
      description: ['', Validators.required],
      privacyPolicy: [false, Validators.requiredTrue],
    });

    this.requestContactService
      .getContactTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          // Mapping of the response values to the values needed for the dropdown
          this.types = response.map((item: ContactType) => ({
            code: item.id,
            label: item.contactType,
          }));
        },
        error: () => {},
        complete: () => {},
      });
  }

  updateCharacterCount(event: Event) {
    const maxLength = 1000;
    const element = event.target as HTMLTextAreaElement;
    const currentLength = element.value.length;
    const charCountElement = document.getElementById('charCount');
    if (charCountElement) {
      charCountElement.textContent = `${currentLength} / ${maxLength} caracteres`;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPartners() {
    this.partners = this.localStorageService.getSelectedPartnersToContact();
    this.selectedPartners = this.partners;
  }

  submitContactRequest() {
    this.recaptchaV3Service.execute('contactRequest').subscribe({
      next: (token) => {
        // Transform selectedPartners to an array of IDs
        const partnerIds = this.selectedPartners.map((partner) => partner.techPartnerId);

        const contactRequestsData = {
          TechPartnerIds: partnerIds,
          OrganizationName: this.requestContactForm.value.organizationName,
          Name: this.requestContactForm.value.name,
          Surname: this.requestContactForm.value.surname,
          Email: this.requestContactForm.value.email,
          ContactTypeId: this.requestContactForm.value.contactType.code,
          Description: this.requestContactForm.value.description,
          HasAcceptedRGPD: this.requestContactForm.value.privacyPolicy,
          ReCaptchaToken: token,
        };

        this.isLoading = true;

        this.requestContactService
          .newContactRequest(contactRequestsData)
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => (this.isLoading = false))
          ) // This will be called when the observable completes
          .subscribe({
            next: () => {
              // Hide request contact elements
              this.isFormSubmitted = true;

              //code to clear form after submission
              this.requestContactForm.reset();
            },
            error: () => {
              this.isLoading = false; // Ensure spinner is hidden if there's an error
            },
            complete: () => {
              //code to clear selected partners from local storage after submission completed
              this.selectedPartners = [];
              this.localStorageService.clearSelectedPartners();
              this.localStorageService.clearSelectedPartnersToContact();
            },
          });
      },
    });
  }

  cancelContactRequest() {
    this.location.back();
  }

  returnToPartners() {
    this.router.navigateByUrl(`/partners`);
  }
}
