import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ContactRequestsData } from '../models/ContactRequestsData.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestContactService {
  private contactRequest = environment.routes.contactRequest;
  private contactType = environment.routes.contactType;

  constructor(private http: HttpClient) {}

  // New Contact Request
  newContactRequest(contactRequestsData: ContactRequestsData) {
    return this.http.post<any>(this.contactRequest, contactRequestsData);
  }

  // Get Contact Types
  getContactTypes() {
    return this.http.get<any>(this.contactType);
  }
}
