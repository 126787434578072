import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { TechPartnerListAllComponent } from './pages/tech-partner-list-all/tech-partner-list-all.component';
import { TechPartnerListComponent } from './pages/tech-partner-list/tech-partner-list.component';
import { CompareTechPartnersComponent } from './pages/compare-tech-partners/compare-tech-partners.component';
import { ContactTechPartnersComponent } from './pages/contact-tech-partners/contact-tech-partners.component';
import { TechPartnerProfileComponent } from './pages/tech-partner-profile/tech-partner-profile.component';
import { AppSearchComponent } from './components/app-search/app-search.component';
import { AppResultsRefinementComponent } from './components/app-results-refinement/app-results-refinement.component';
import { AppChangeFiltersDialogComponent } from './components/app-change-filters-dialog/app-change-filters-dialog.component';
import { AppPartnerCardComponent } from './components/app-partner-card/app-partner-card.component';
import { AppPaginatorComponent } from './components/app-paginator/app-paginator.component';
import { AppPartnersSelectionFooterComponent } from './components/app-partners-selection-footer/app-partners-selection-footer.component';
import { AppFaqCardComponent } from './components/app-faq-card/app-faq-card.component';
import { AppComparePartnerCardComponent } from './components/app-compare-partner-card/app-compare-partner-card.component';
import { AppTestimonyQuoteCardComponent } from './components/app-testimony-quote-card/app-testimony-quote-card.component';
import { AppCaseStudyCardComponent } from './components/app-case-study-card/app-case-study-card.component';
import { AppToastComponent } from './components/app-toast/app-toast.component';
import { AppBreadcrumbComponent } from './components/app-breadcrumb/app-breadcrumb.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLoaderSpinnerComponent } from './components/app-loader-spinner/app-loader-spinner.component';
import { RouterOutlet } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import {
  RecaptchaModule,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from 'ng-recaptcha';

//Primeng components
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ChipModule } from 'primeng/chip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { DataViewModule } from 'primeng/dataview';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CarouselModule } from 'primeng/carousel';
import { QRCodeModule } from 'angularx-qrcode';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { DialogModule } from 'primeng/dialog';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { ShellComponent } from './shell/shell/shell.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TechPartnerListAllComponent,
    TechPartnerListComponent,
    CompareTechPartnersComponent,
    ContactTechPartnersComponent,
    TechPartnerProfileComponent,
    AppSearchComponent,
    AppResultsRefinementComponent,
    AppChangeFiltersDialogComponent,
    AppPartnerCardComponent,
    AppPaginatorComponent,
    AppPartnersSelectionFooterComponent,
    AppFaqCardComponent,
    AppComparePartnerCardComponent,
    AppTestimonyQuoteCardComponent,
    AppCaseStudyCardComponent,
    AppToastComponent,
    AppBreadcrumbComponent,
    AppLoaderSpinnerComponent,
    ShellComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ButtonModule,
    BreadcrumbModule,
    ChipModule,
    ReactiveFormsModule,
    InputTextareaModule,
    InputTextModule,
    CheckboxModule,
    ToastModule,
    RippleModule,
    CardModule,
    CheckboxModule,
    CommonModule,
    DataViewModule,
    FormsModule,
    TooltipModule,
    DropdownModule,
    ProgressSpinnerModule,
    RouterOutlet,
    MultiSelectModule,
    AccordionModule,
    IconFieldModule,
    InputIconModule,
    CarouselModule,
    QRCodeModule,
    AccordionModule,
    DialogModule,
    InfiniteScrollDirective,
    OverlayPanelModule,
    RecaptchaModule,
    RecaptchaV3Module,
    NgxGoogleAnalyticsModule.forRoot(environment.tag_analytics),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    MessageService,
    AppToastComponent,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Lc7xAoqAAAAAIkRuRZZgQowdFjBdPiNpwglH93T',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
