const GATEWAY_URL = 'https://core-gateway-dev.gs1pt.org/';
const BASE_ROUTE = GATEWAY_URL + 'api/digital-directory/';
const TAG_ANALYTICS = 'G-CK7VG1M3JF';

const ROUTES = {
  techPartner: BASE_ROUTE + 'techpartner',
  techPartnerProfile: BASE_ROUTE + 'techpartner',
  contactRequest: BASE_ROUTE + 'contact-request',
  contactType: BASE_ROUTE + 'contact-type',
  techPartnerFilters: BASE_ROUTE + 'techpartner' + '/filters',
  filteredTechParners: BASE_ROUTE + 'techpartner' + '/filtered-partners',
};

export const environment = {
  production: false,
  hmr: false,
  serviceworker: true,
  gatewayUrl: GATEWAY_URL,
  routes: ROUTES,
  mock: false,
  tag_analytics: TAG_ANALYTICS
};
