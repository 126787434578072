import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-app-toast',
  templateUrl: './app-toast.component.html',
  styleUrl: './app-toast.component.scss',
})
export class AppToastComponent {
  constructor(private messageService: MessageService) {}

  show() {
    this.messageService.add({
      severity: 'success',
      summary: 'O seu pedido de contacto foi submetido com sucesso',
      detail: 'Será postumamente contactado diretamente pelo(s) parceiro(s)',
      sticky: true,
    });
  }
}
