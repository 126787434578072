import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
  input,
} from '@angular/core';
import { LocalStorageService } from '../../services/localStorage.service';
import { TechPartnerPartnerProfile } from '../../models/TechPartnerPartnerProfile';
import { TechPartnerFullProfile } from '../../models/TechPartnerFullProfile';
import { ActivatedRoute, Router } from '@angular/router';
import { TechPartnerFullCard } from '../../models/TechPartnerFullCard.model';
import { TechPartnerService } from '../../services/techpartner.service';
import { Subject, finalize, takeUntil } from 'rxjs';
import { Carousel } from 'primeng/carousel';
import { TechPartnerFile } from '../../models/TechPartnerFile.model';
import { SelectedPartner } from '../../models/SelectedPartner.model';
import { TechPartnerCaseStudy } from '../../models/TechPartnerCaseStudy';

@Component({
  selector: 'app-tech-partner-profile',
  templateUrl: './tech-partner-profile.component.html',
  styleUrl: './tech-partner-profile.component.scss',
})
export class TechPartnerProfileComponent {
  techPartnerService = inject(TechPartnerService);
  @ViewChild('carousel') carousel?: Carousel;

  private unsubscribe$: Subject<void> = new Subject<void>();
  techPartnerProfile!: TechPartnerFullProfile;

  partnerId: number = 0;

  fileUrl: string = "";

  activeIndices?: number[] = [];

  showAccordion: boolean = false;


  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  isLoading: boolean = true;

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.partnerId = params['id'];
        this.loadTechPartnerProfile();
      });

  }

  getAccordionActiveIndexes() {
    const nestedAccordionLength = this.techPartnerProfile.productServices.length;
    const defaultIndexes = this.showAccordion ? 5 : 4;
    const activeIndexesSize = defaultIndexes + nestedAccordionLength;
  
    this.activeIndices = Array.from({ length: activeIndexesSize }, (_, index) => index);
  }

  getColor(level: number) {
    switch (level) {
      case 2:
        return '#FFBA09';
      case 3:
        return 'var(--gs1-color-ui-5)';
      case 4:
        return 'var(--gs1-color-tangerine-accessible)';
      default:
        return 'transparent';
    }
  }


  getImage(fileData?: any, fileNameWithExtension?: string) {
    if (fileData != null && fileNameWithExtension != null) {
      var fileExtension = fileNameWithExtension.split('.').pop();
      if (fileExtension === 'svg') {
        fileExtension = fileExtension + '+xml'
      }
      return "data:image/" + fileExtension + ";charset=utf-8;base64," + fileData;
    }
    else {
      return null;
    }
  }

  onRequestContact() {

    const mappedSelectedPartners: SelectedPartner[] = [this.techPartnerProfile].map(partner => {
      const selectedPartner: SelectedPartner = {
        companyId: partner.partnerProfile.companyId,
        id: partner.partnerProfile.id,
        name: partner.partnerProfile.companyName,
        techPartnerId: partner.partnerProfile.techPartnerId
      };
      
      return selectedPartner;
    });

    this.localStorageService.saveSelectedPartnersToContact(mappedSelectedPartners);
    this.router.navigateByUrl(`/contact`);
  }

  downloadFile(caseStudy: TechPartnerCaseStudy): void {
    if (caseStudy.fileData && caseStudy.fileNameWithExtension) {
      const byteCharacters = atob(caseStudy.fileData);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: `application/${caseStudy.fileNameWithExtension.split('.').pop()}` });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `download.${caseStudy.fileNameWithExtension.split('.').pop()}`;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    } else {
      console.error('Invalid case study data or file extension.');
    }
  }

  onTabChange(event: any) {
    this.activeIndices = event.index;
  }

  getClickableLink = (link: string) => {
    return link.startsWith("http://") || link.startsWith("https://") ?
      link
      : `http://${link}`;
  };

  loadTechPartnerProfile() {
    this.isLoading = true;
    this.techPartnerService
      .loadTechPartnerFullProfile(this.partnerId)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (response) => {
          this.techPartnerProfile = response;
          this.techPartnerProfile.contacts = this.techPartnerProfile.contacts.map(c => {
            c.contactInfoWebSiteURL = this.getClickableLink(c.contactInfoWebSiteURL);
            c.contactInfoQRCodeURL = this.getClickableLink(c.contactInfoQRCodeURL);
            return c;
          });
          this.getAccordionActiveIndexes();
          this.getImage();
        },
        error: (error) => {
          console.error('Error loading tech partners:', error);
          this.isLoading = false;
        },
      });
  }
}
