<ng-container *ngIf="isLoading">
  <div class="loading-container">
    <app-app-loader-spinner [isLoading]="isLoading"></app-app-loader-spinner>
  </div>
</ng-container>

<div *ngIf="!isFormSubmitted && !isLoading" class="componentContentWraper">
  <h5 class="componentTitle">Pedido de Contacto</h5>
  <div class="selectedPartnersSection">
    <p class="highlightedText">
      Pedido de contacto a ser enviado para as seguintes empresas:
    </p>
    <div class="partnersGap">
      <p-checkbox
        *ngFor="let partner of partners"
        [label]="partner.name"
        [value]="partner"
        [(ngModel)]="selectedPartners"
      ></p-checkbox>
    </div>
  </div>
  <div class="requestContactFormSection">
    <form [formGroup]="requestContactForm">
      <div class="form-group">
        <label for="name">Nome</label>
        <input
          id="name"
          type="text"
          pInputText
          class="p-inputtext ng-valid input"
          formControlName="name"
          placeholder="Insira o seu nome"
        />
      </div>

      <div class="form-group">
        <label for="surname">Apelido</label>
        <input
          id="surname"
          type="text"
          pInputText
          class="p-inputtext ng-valid input"
          formControlName="surname"
          placeholder="Insira o seu apelido"
        />
      </div>

      <div class="form-group">
        <label for="email">Email*</label>
        <input
          id="email"
          type="email"
          pInputText
          class="p-inputtext ng-valid input"
          formControlName="email"
          placeholder="Insira o seu email"
        />
      </div>

      <div class="form-group">
        <label for="organization">Nome da Organização*</label>
        <input
          id="organization"
          type="text"
          pInputText
          class="p-inputtext ng-valid input"
          formControlName="organizationName"
          placeholder="Insira o nome da sua organização"
        />
      </div>

      <div class="form-group">
        <label for="contactType">Tipificação do contacto*</label>
        <p-dropdown
          id="contactType"
          formControlName="contactType"
          [options]="types"
          optionLabel="label"
          class="ng-valid input"
          placeholder="Escolha o tipo de contacto"
        ></p-dropdown>
      </div>

      <div class="form-group">
        <label for="description">Descrição do pretendido*</label>
        <div class="textarea-container">
          <textarea
            rows="4"
            id="description"
            pInputTextarea
            class="p-inputtextarea ng-valid textarea"
            formControlName="description"
            placeholder="Descreva brevemente o pretendido"
            maxlength="1000"
            (input)="updateCharacterCount($event)"
          ></textarea>
          <span id="charCount" class="char-count"></span>
        </div>
      </div>
    </form>
  </div>
  <div class="privacyPolicySection">
    <p>
      Os dados recolhidos serão processados pela GS1 Portugal para efeitos de
      gestão da formação.<br />
      Saiba mais na nossa <a href="https://gs1pt.org/politica-de-privacidade/">Política de Privacidade</a>.
    </p>

    <div class="form-check" [formGroup]="requestContactForm">
      <p-checkbox
        formControlName="privacyPolicy"
        [binary]="true"
        inputId="privacyPolicy"
      ></p-checkbox>
      <label for="privacyPolicy">
        Tomei conhecimento do tratamento dos dados ao abrigo da política de
        privacidade da GS1 Portugal.
      </label>
    </div>
  </div>
  <div class="buttonsSection">
    <button pButton class="btn btn-link-black" (click)="cancelContactRequest()">
      Cancelar
    </button>

    <button
      pButton
      class="btn btn-primary"
      [disabled]="!requestContactForm.valid || selectedPartners.length === 0"
      (click)="submitContactRequest()"
    >
      Submeter
    </button>
  </div>
</div>

<div class="submitedWraper" *ngIf="isFormSubmitted">
  <div class="contentWraper">
    <div class="notification-box">
      <h1>O seu pedido de contacto foi submetido com sucesso</h1>
      <p>Será posteriormente contactado diretamente pelo(s) parceiro(s)</p>
    </div>
    <div class="button-container">
      <button pButton class="btn btn-link-black" (click)="returnToPartners()">
        Voltar à Página de Pesquisa
      </button>
    </div>
  </div>
</div>
