import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FaqResponse } from '../models/FaqResponse.model';

@Injectable({
    providedIn: 'root',
})
export class FaqService {

    constructor(private http: HttpClient) { }

    getImageUrl(featured_media: number): Observable<any> {
        const jsonUrl = "https://gs1pt.org/wp-json/wp/v2/media/" + featured_media + "?_fields=link"
        return this.http.get<any>(jsonUrl);
    }

    getFaqs(): Observable<any> { 
        const getFaqsUrl = "https://gs1pt.org/wp-json/wp/v2/posts?categories=38&_fields=title,link,excerpt,featured_media";
        return this.http.get<FaqResponse[]>(getFaqsUrl);
    }
}
