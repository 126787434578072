import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class FiltersService {

  constructor() { }

  private filters: any = {};

  setFilters(filters: any) {
    this.filters = filters;
  }

  getFilters() {
    return this.filters;
  }
}
