<div class="container">
    <h4>Comparação de Parceiros</h4>

    <ng-container #loading>
        <app-app-loader-spinner  [isLoading]="isLoading"></app-app-loader-spinner>
      </ng-container>

    <div class="grid">
        <ng-container *ngFor="let item of partners">
            <div class="box">
                <app-app-compare-partner-card class="cardListItem" [partner]="item"
                    (selectPartner)="handlePartnerSelection(item, $event)" [isSelected]="isPartnerSelected(item)"
                    [isCheckboxDisabled]="isCheckboxDisabled(item)"></app-app-compare-partner-card>
            </div>
        </ng-container>
    </div>

    <div class="buttons">
        <p-button label="Voltar aos Resultados" styleClass="btn btn-link-blue left"
            (onClick)="goBackToSearchResults()"></p-button>

        <p-button label="Pedir Contactos" styleClass="btn btn-primary button-arrow"
            (onClick)="onRequestContact()" [disabled]="this.selectedPartners.length === 0 || !areValidPartnersSelected()">
        </p-button>
    </div>

</div>