<!-- TODO get the correct values coming from the api -->
<div class="container" [ngClass]="{ 'selected-card': isSelected }">
  <div class="header-line" [ngStyle]="{
      'background-color': getColor(partner.card.techPartnerTypeId ?? 0)
    }"></div>

  <div class="header-content">
    <span class="header-label" [ngStyle]="{
        'background-color': getColor(partner.card.techPartnerTypeId ?? 0)
      }">
      {{
      partner.card.typeDescription === "null"
      ? " "
      : partner.card.typeDescription
      }}
    </span>
    <div class="spacer"></div>
    <p-checkbox label="Selecionar" styleClass="checkbox" [disabled]="isCheckboxDisabled" [(ngModel)]="isSelected"
      pTooltip="Limite de seleção atingido (4)" [binary]="true" (onChange)="onCheckboxChange($event)"
      [tooltipDisabled]="!isCheckboxDisabled" tooltipStyleClass="p-tooltip" tooltipPosition="bottom"></p-checkbox>
  </div>

  <div class="content">
    <div class="title">
      <div class="title-image">
        <img alt="Logo da empresa" [src]="this.fileUrl" *ngIf="fileUrl" />
      </div>
      <div class="title-text">
        <span class="name" pTooltip="{{ partner.card.companyName }}">{{ partner.card.companyName }}</span>
      </div>
    </div>

    <div class="subtitle">Certificações GS1</div>

    <section class="certifications-services-container">
      <ng-container *ngIf="
          partner.certifications &&
            partner.certifications.length > 0 &&
            partner.certifications[0].certificationDescription">
        <ng-container *ngFor="let certification of partner.certifications">
          <span class="tag" title="{{ certification.certificationDescription }}">
            <img alt="logo da certificação" *ngIf="certification.file" class="miniImg"
              [src]="getImageTag(certification.file.fileData, certification.file.fileNameWithExtension)" />
            {{certification.certificationDescription}}</span>
        </ng-container>
      </ng-container>
    </section>

    <div class="subtitle">Serviços Prestados</div>
    <section class="certifications-services-container">
      <ng-container *ngFor="let service of getServices().slice(0, 5); let i = index">
        <span class="tag" title="{{ service.descriptionTag }}">
          <img alt="logo da tag" *ngIf="service.file" class="miniImg"
            [src]="getImageTag(service.file.fileData, service.file.fileNameWithExtension)" />
          {{ service.descriptionTag }}
        </span>
      </ng-container>

      <!-- OverlayPanel with remaining services -->
      <span *ngIf="getServices().length > 5" class="tag more-services" (click)="overlayPanel.toggle($event)">
        ...
      </span>

      <!-- OverlayPanel content -->
      <p-overlayPanel #overlayPanel>
        <div *ngFor="let service of getServices().slice(5)">
          <span class="overlay-service">{{ service.descriptionTag }}</span>
        </div>
      </p-overlayPanel>
    </section>

    <span class="description-title"></span>
    <section class="size-description">
      <p class="description" title="{{ partner.card.baseDescription }}">
        {{ partner.card.baseDescription }}
      </p>
    </section>
  </div>

  <p-button label="Ver Perfil do Parceiro" styleClass="btn btn-primary button-arrow btn-card"
    (onClick)="visitProfile()"></p-button>
</div>