<!-- <p>home page works!</p>
<button pButton class="btn btn-primary" (click)="navigationAll()">
  Todos os parceiros
</button>
<button pButton class="btn btn-primary" (click)="navigationFiltered()">
  Filtrados
</button> -->

<section class="wrapper">
  <div class="grid justify-content-center">
    <div class="faq-section">
      <div class="faq-cards" *ngFor="let faq of faqs;">
        <app-app-faq-card [faq]="faq">
        </app-app-faq-card>
      </div>
    </div>
  </div>
</section>