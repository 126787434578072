import { AfterContentInit, AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { HeaderFooterService } from './services/header-footer.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class AppComponent {

}
