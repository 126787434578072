import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/localStorage.service';
import { TechPartnerFullCard } from '../../models/TechPartnerFullCard.model';
import { Router } from '@angular/router';
import { SelectedPartner } from '../../models/SelectedPartner.model';
import { FilterTag } from '../../models/FilterTag.model';

@Component({
  selector: 'app-app-partner-card',
  templateUrl: './app-partner-card.component.html',
  styleUrls: ['./app-partner-card.component.scss'],
})
export class AppPartnerCardComponent implements OnInit {
  @Input() partner!: TechPartnerFullCard;
  @Input() isCheckboxDisabled: boolean = false;
  @Input() isSelected: boolean = false;
  @Output() selectPartner = new EventEmitter<boolean>();

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  fileUrl: string = '';

  ngOnInit() {
    this.getImage();
    this.updateSelectedStatus();
  }

  onCheckboxChange(event: any) {
    this.selectPartner.emit(event.checked);
  }

  isPartnerSelected(): boolean {
    const partners = this.localStorageService.getSelectedPartners();
    return partners.some((partner) => partner.card.id === this.partner.card.id);
  }

  updateSelectedStatus() {
    this.isSelected = this.isPartnerSelected();
  }

  getServices(): FilterTag[] {
    const tagType = this.partner.tagTypes.filter(x => x.tagTypeId == 2);
    return tagType ?? [];
  }

  //TODO Change to correct level names
  getColor(level: number) {
    switch (level) {
      case 2:
        return '#FFBA09';
      case 3:
        return 'var(--gs1-color-ui-5)';
      case 4:
        return 'var(--gs1-color-tangerine-accessible)';
      default:
        return 'transparent';
    }
  }

  visitProfile() {
    this.router.navigateByUrl(`partner-profile/` + this.partner.card.id);
  }

  getImage() {
    if (this.partner.card.fileData && this.partner.card.fileNameWithExtension) {
      var fileExtension = this.partner.card.fileNameWithExtension.split('.').pop();
      if (fileExtension === 'svg') {
        fileExtension = fileExtension + '+xml'
      }
      this.fileUrl = "data:image/" + fileExtension + ";charset=utf-8;base64," + this.partner.card.fileData;
    }
  }

  getImageTag(fileData?: any, fileNameWithExtension?: string) {
    if (fileData != null && fileNameWithExtension != null) {
      var fileExtension = fileNameWithExtension.split('.').pop();
      if (fileExtension === 'svg') {
        fileExtension = fileExtension + '+xml'
      }
      return "data:image/" + fileExtension + ";charset=utf-8;base64," + fileData;
    }
    else {
      return null;
    }
  }

  convertToBase64(fileData: string): string {
    return atob(fileData);
  }

  getRemainingServices(): string {
    const remainingServices = this.getServices().slice(5);
    return remainingServices.map(service => service.descriptionTag).join(', ');
  }
}
