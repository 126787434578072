import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TechPartnerFullCard } from '../../models/TechPartnerFullCard.model';
import { LocalStorageService } from '../../services/localStorage.service';
import { Router } from '@angular/router';
import { SelectedPartner } from '../../models/SelectedPartner.model';
import { FilterTag } from '../../models/FilterTag.model';

@Component({
  selector: 'app-app-compare-partner-card',
  templateUrl: './app-compare-partner-card.component.html',
  styleUrl: './app-compare-partner-card.component.scss'
})
export class AppComparePartnerCardComponent {

  @Input() partner!: TechPartnerFullCard;
  @Input() isCheckboxDisabled: boolean = false;
  @Input() isSelected: boolean = false;
  @Output() selectPartner = new EventEmitter<boolean>();

  fileUrl: string = "";


  constructor(private localStorageService: LocalStorageService, private router: Router) { }

  ngOnInit() {
    this.updateSelectedStatus();
    this.getImage();
  }
  
  onCheckboxChange(event: any) {
    this.selectPartner.emit(event.checked);
  }
  
  isPartnerSelected(): boolean {
    const partners = this.localStorageService.getSelectedPartnersToContact();
    return partners.some(partner => partner.companyId === this.partner.card.companyId);
  }
  
  updateSelectedStatus() {
    this.isSelected = this.isPartnerSelected();
    this.selectPartner.emit(this.isSelected);    
  }
  
  getServices(): FilterTag[] {
    const tagType = this.partner.tagTypes.filter(x => x.tagTypeId == 2);
    return tagType ?? [];
  }

  //TODO Change to correct level names
  getColor(level: number) {
    switch (level) {
      case 2:
        return '#FFBA09';
      case 3:
        return 'var(--gs1-color-ui-5)';
      case 4:
        return 'var(--gs1-color-tangerine-accessible)';
      default:
        return 'transparent';
    }
  }

  getImage() {
    if (this.partner.card.fileData && this.partner.card.fileNameWithExtension) {
      var fileExtension = this.partner.card.fileNameWithExtension.split('.').pop();
      if (fileExtension === 'svg') {
        fileExtension = fileExtension + '+xml'
      }
      this.fileUrl = "data:image/" + fileExtension + ";charset=utf-8;base64," + this.partner.card.fileData;
    }
  }

  getImageTag(fileData?: any, fileNameWithExtension?: string) {
    if (fileData && fileNameWithExtension) {
      var fileExtension = fileNameWithExtension.split('.').pop();
      if (fileExtension === 'svg') {
        fileExtension = fileExtension + '+xml'
      }
      return "data:image/" + fileExtension + ";charset=utf-8;base64," + fileData;
    }
    return null;
  }

  onViewProfile() {
    this.router.navigateByUrl(`partner-profile/` + this.partner.card.id);
  }
}
