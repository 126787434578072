<div class="background-fill-profile">
  <div class="componentContentWraper background-fill-container-profile">

    <ng-container #loading>
      <app-app-loader-spinner [isLoading]="isLoading"></app-app-loader-spinner>
    </ng-container>


    <ng-container *ngIf="techPartnerProfile?.partnerProfile && !isLoading; else partnerNotFound">

      <section class="basic-info">
        <h2>Página de Perfil</h2>

        <section class="div-top-partner-logo">
          <div class="title-logo">
            <h4 class="partnerName" style="word-break: break-word;">
              {{ techPartnerProfile.partnerProfile.companyName }}
            </h4>
            <div>
              <img alt="Logo do Parceiro" class="logo" *ngIf="techPartnerProfile.partnerProfile.fileData"
                [src]="getImage(this.techPartnerProfile.partnerProfile.fileData, this.techPartnerProfile.partnerProfile.fileNameWithExtension)" />
            </div>
          </div>
          <span class="header-label" *ngIf="
            techPartnerProfile.partnerProfile.techPartnerTypeDescription !==
            'null'
            " [ngStyle]="{
              'background-color': getColor(
                techPartnerProfile.partnerProfile.techPartnerTypeId
              )
            }">
            {{ techPartnerProfile.partnerProfile.techPartnerTypeDescription }}
          </span>
        </section>

        <div>
          <span class="title-profileDescription"></span>
          <p class="text-profileDescription" style="word-wrap: break-word;">
            {{ techPartnerProfile.partnerProfile.profileDescription }}
          </p>
        </div>
      </section>

      <!-- Certificações GS1  -->
      <div>
        <div class="sub-menu-1-container">
          <div class="sub-menu-1-header">Certificações GS1</div>
        </div>
        <div class="sub-menu-1-container-text">
          <ng-container *ngFor="let certification of techPartnerProfile.certifications">
            <ng-container *ngIf="certification.certificationDescription != null">
              <span class="tag" title="{{ certification.certificationDescription }}">
                <img *ngIf="certification.file" class="miniImg" alt="logo da certificação"
                  [src]="getImage(certification.file.fileData, certification.file.fileNameWithExtension)" />
                {{ certification.certificationDescription }}</span>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Serviços Prestados  -->
      <div>
        <div class="sub-menu-1-container">
          <div class="sub-menu-1-header">Serviços Prestados</div>
        </div>
        <div class="sub-menu-1-container-text">
          <ng-container *ngFor="let tag of techPartnerProfile.tags">
            <ng-container *ngIf="tag.tagTypeId != null">
              <span class="tag" *ngIf="tag.tagTypeId == 2" title="{{ tag.descriptionTag }}">
                <img *ngIf="tag.file" class="miniImg" alt="tag"
                  [src]="getImage(tag.file.fileData, tag.file.fileNameWithExtension)" />
                {{ tag.descriptionTag }}
              </span>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Setores onde Atuam  -->
      <div>
        <div class="sub-menu-1-container">
          <div class="sub-menu-1-header">Setores onde Atuam</div>
        </div>
        <div class="sub-menu-1-container-text">
          <ng-container *ngFor="let tag of techPartnerProfile.tags">
            <ng-container *ngIf="tag.tagTypeId != null">
              <span class="tag" *ngIf="tag.tagTypeId == 4" title="{{ tag.descriptionTag }}">
                <img *ngIf="tag.file" class="miniImg" alt="logo da tag"
                  [src]="getImage(tag.file.fileData, tag.file.fileNameWithExtension)" />
                {{ tag.descriptionTag }}
              </span>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Regiões onde Atuam  -->
      <div>
        <div class="sub-menu-1-container">
          <div class="sub-menu-1-header">Regiões onde Atuam</div>
        </div>
        <div class="sub-menu-1-container-text">
          <ng-container *ngFor="let tag of techPartnerProfile.tags">
            <ng-container *ngIf="tag.tagTypeId != null">
              <span class="tag" *ngIf="tag.tagTypeId == 3" title="{{ tag.descriptionTag }}">
                <img *ngIf="tag.file" class="miniImg" alt="logo da tag"
                  [src]="getImage(tag.file.fileData, tag.file.fileNameWithExtension)" />
                {{ tag.descriptionTag }}
              </span>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Start of "Partner != null"  -->

      <p-accordion [multiple]="true" [activeIndex]="activeIndices" (onChange)="onTabChange($event)">
        <!-- Características  -->
        <p-accordionTab iconPos="end" class="sub-menu-1-header" header="Características" *ngIf="
          techPartnerProfile.partnerProfile.techPartnerTypeDescription !== 'null'
        ">
          <ul class="feature-list">
            <li class="feature-item-2" *ngIf="techPartnerProfile.partnerProfile.firstAdvantage !== ''">
              {{ techPartnerProfile.partnerProfile.firstAdvantage }}
            </li>
            <li class="feature-item-2" *ngIf="techPartnerProfile.partnerProfile.secondAdvantage !== ''">
              {{ techPartnerProfile.partnerProfile.secondAdvantage }}
            </li>
            <li class="feature-item-2" *ngIf="techPartnerProfile.partnerProfile.thirdAdvantage !== ''">
              {{ techPartnerProfile.partnerProfile.thirdAdvantage }}
            </li>
          </ul>
        </p-accordionTab>

        <!-- Produtos e Serviços  -->
        <p-accordionTab iconPos="end" class="sub-menu-1-header" header="Produtos e Serviços"
          *ngIf="techPartnerProfile.partnerProfile.techPartnerTypeDescription != 'null'">
          <div *ngFor="let productServicesType of techPartnerProfile.productServices">
            <div *ngIf="productServicesType.descriptionProductService != null">
              <p-accordion>
                <p-accordionTab iconPos="end" class="sub-menu-1-header" header="{{ productServicesType.title }}"
                  class="product-service-accordion">
                  <p class="case-study-description">
                    {{ productServicesType.descriptionProductService }}
                  </p>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>
        </p-accordionTab>

        <!-- Testemunhos  -->
        <p-accordionTab iconPos="end" class="sub-menu-1-header" header="Testemunhos" *ngIf="
          techPartnerProfile.partnerProfile.techPartnerTypeDescription != 'null' && showAccordion
        ">
          <ng-container *ngIf="techPartnerProfile.testimonies.length > 0">
            <p-carousel #carousel class="p-testemunhos-carousel" [value]="techPartnerProfile.testimonies"
              [numVisible]="2" [numScroll]="1" [circular]="true" [autoplayInterval]="5000" [showNavigators]="false"
              [showIndicators]="false">
              <ng-template let-testimony pTemplate="item">
                <div class="p-testemunhos-carousel-item">
                  <p-card class="p-testemunhos-card">
                    <img src="../../../assets/testimony_circle.svg" alt="Testemunho" class="corner-image" />
                    <ng-template pTemplate="title">
                      <h5>{{ testimony.testimony }}</h5>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <div class="title-logo-testimony">
                        <img alt="Imagem do Testemunho" class="logo" [src]="
                          'data:image/' +
                          testimony.fileNameWithExtension +
                          ';base64,' +
                          testimony.fileData
                        " />
                        <div class="text-container">
                          <p class="partnerName">
                            {{ testimony.nameTestimony }}
                          </p>
                          <p class="partnerCompany">{{ testimony.company }}</p>
                        </div>
                      </div>
                    </ng-template>
                  </p-card>
                </div>
              </ng-template>
            </p-carousel>
            <div class="testemunhos-carousel-controls">
              <button class="testemunhos-carousel-control" (click)="carousel.navBackward($event)">
                <img alt="Recuar" src="../../../assets/1_arrow_carousel.svg" class="image-controls" />
              </button>
              <button class="testemunhos-carousel-control" (click)="carousel.navForward($event)">
                <img alt="Avançar" src="../../../assets/2_arrow_carousel.svg" class="image-controls" />
              </button>
            </div>
          </ng-container>

        </p-accordionTab>

        <!-- Casos de Estudo  -->
        <p-accordionTab iconPos="end" class="sub-menu-1-header" header="Casos de Estudo" *ngIf="
          techPartnerProfile.partnerProfile.techPartnerTypeDescription != 'null'
        ">
          <div *ngFor="let caseStudy of techPartnerProfile.caseStudies" class="case-study-container">
            <div *ngIf="caseStudy.nameCaseStudy != null">
              <div class="case-study-header">
                <h5 class="case-study-title">{{ caseStudy.nameCaseStudy }}</h5>
                <img src="../../../assets/picture_as_pdf.svg" alt="PDF icon" (click)="downloadFile(caseStudy)"
                  class="case-study-icon" *ngIf="caseStudy.fileData" />
              </div>
              <p class="case-study-description">
                {{ caseStudy.descriptionCaseStudy }}
              </p>
            </div>
          </div>
        </p-accordionTab>

        <!-- End of "Partner != null"  -->

        <!-- Contactos  -->
        <p-accordionTab iconPos="end" class="sub-menu-1-header" header="Contactos">
          <div class="sub-menu-2-container-text">
            <ng-container>
              <div class="contact-info" *ngFor="let contact of techPartnerProfile.contacts">
                <div class="contact" *ngIf="contact.contactInfoFirstName != null">
                  <div class="contact-header">Pessoa de Contacto #1</div>
                  <p class="contact-label">Nome</p>
                  <span class="contact-value">{{ contact.contactInfoFirstName }}</span>
                  <p class="contact-label">Telefone</p>
                  <span class="contact-value">{{
                    contact.contactInfoFirstPhoneNumber
                    }}</span>
                  <p class="contact-label">Email</p>
                  <span class="contact-value">{{
                    contact.contactInfoFirstEmail
                    }}</span>
                </div>
                <div class="contact" *ngIf="
                  techPartnerProfile.partnerProfile
                    .techPartnerTypeDescription != 'null' &&
                  contact.contactInfoSecondName != null
                ">
                  <div class="contact-header">Pessoa de Contacto #2</div>
                  <p class="contact-label">Nome</p>
                  <span class="contact-value">{{ contact.contactInfoSecondName }}</span>
                  <p class="contact-label">Telefone</p>
                  <span class="contact-value">{{
                    contact.contactInfoSecondPhoneNumber
                    }}</span>
                  <p class="contact-label">Email</p>
                  <span class="contact-value">{{
                    contact.contactInfoSecondEmail
                    }}</span>
                </div>
                <div class="web-info" *ngIf="
                  contact.contactInfoWebSiteURL != null &&
                  contact.contactInfoQRCodeURL != null
                ">
                  <div class="web-header contact-header">Página Web</div>
                  <a [href]="contact.contactInfoWebSiteURL" class="link-button">Link do Site</a>
                  <qrcode [qrdata]="contact.contactInfoQRCodeURL" [width]="237" [errorCorrectionLevel]="'M'"
                    [allowEmptyString]="true"></qrcode>
                </div>
              </div>
            </ng-container>
          </div>
        </p-accordionTab>
      </p-accordion>

      <!-- Botão  -->
      <div class="right-buttons">
        <p-button label="Pedir Contacto" *ngIf="techPartnerProfile.partnerProfile.techPartnerTypeDescription !== 'null'"
          styleClass="btn btn-primary" (onClick)="onRequestContact()">
        </p-button>
      </div>

    </ng-container>

    <ng-template #partnerNotFound>
      <div class="partner-not-found" *ngIf="!isLoading">
        <p>Perfil do parceiro não encontrado.</p>
      </div>
    </ng-template>

  </div>
</div>