<div class="breadcrumb-background">
  <div class="filter">
    <h2 style="text-align: center;">Diretório de Parceiros Tecnológicos</h2>

    <div class="card flex justify-content-center">
      <div *ngIf="showSearch; else breadcrumbTemplate">
        <div class="search-container" [formGroup]="myGroup">
          <div class="search-row">
            <span class="breadcrumb-text"> Sou do setor </span>
            <p-dropdown
              optionLabel="name"
              [options]="sectors"
              formControlName="sector"
              placeholder="Escolha uma opção"
              [filter]="true"
              [showClear]="true"
            ></p-dropdown>
            <span class="breadcrumb-text"> e procuro </span>
            <p-dropdown
              optionLabel="name"
              [options]="services"
              formControlName="service"
              placeholder="Escolha uma opção"
              [filter]="true"
              [showClear]="true"
            ></p-dropdown>
            <span class="breadcrumb-text"> na região </span>
            <p-dropdown
              optionLabel="name"
              [options]="regions"
              formControlName="region"
              placeholder="Escolha uma opção"
              [filter]="true"
              [showClear]="true"
            ></p-dropdown>
            <button
              pButton
              class="btn btn-primary btn-home-search"
              (click)="applyFiltersAndNavigate()"
              [disabled]="isLoading"
            >
              <ng-container *ngIf="!isLoading">Pesquisar</ng-container>
              <ng-container *ngIf="isLoading">
                <i class="pi pi-spin pi-spinner"></i> A Pesquisar...
              </ng-container>
            </button>
          </div>
          <div class="search-row">
            <p-button
              label="Ver todos os parceiros"
              styleClass="btn btn-link-white"
              (onClick)="navigationAll()"
            ></p-button>
          </div>
        </div>
      </div>
      <ng-template #breadcrumbTemplate>
        <p-breadcrumb class="max-w-full" [model]="items">
          <ng-template style="display: flex; justify-content: center;" pTemplate="item" let-item>
            <a
              [routerLink]="item.route"
              class="p-menuitem-link "
              [ngClass]="{ inactive: !item.isCurrent }"
            >
              <span>{{ item.label }}</span>
            </a>
          </ng-template>
          <ng-template pTemplate="separator"> / </ng-template>
        </p-breadcrumb>
      </ng-template>
    </div>
  </div>
</div>

<div class="card flex justify-content-center dialogContainer">
  <p-dialog
    [(visible)]="displayModal"
    [modal]="true"
    position="top"
    closeOnEscape="false"
    closable="false"
    draggable="false"
    showHeader="false"
  >
    <div class="flex flex-column filtersDialog">
      <div *ngIf="modalMessages.length > 0">
        <h4>Nenhum resultado encontrado</h4>
        <p *ngFor="let message of modalMessages">{{ message }}</p>
      </div>
    </div>
  </p-dialog>
</div>
