import { Component, Input, inject } from '@angular/core';

@Component({
  selector: 'app-app-loader-spinner',
  templateUrl: './app-loader-spinner.component.html',
  styleUrl: './app-loader-spinner.component.scss'
  
})
export class AppLoaderSpinnerComponent {
  @Input() isLoading: boolean = false;
}
