import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FaqService } from '../../services/faq.service';
import { FaqResponse } from '../../models/FaqResponse.model';
import { FaqImageResponse } from '../../models/FaqImageResponse.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  constructor(private router: Router, private faqService: FaqService) { }

  faqs: FaqResponse[] = [];
  imageFaqs: FaqImageResponse[] = [];

  ngOnInit() {
    this.getFaqs();
  }

  navigationAll() {
    this.router.navigateByUrl('/partners');
  }

  navigationFiltered() {
    this.router.navigateByUrl('/filtered-partners');
  }

  getFaqs() {
    this.faqService.getFaqs().subscribe(
      data => {
        this.faqs = data;
        this.faqs.reverse()
      },
      error => console.error('Error fetching the image URL:', error)
    );
  }

}
