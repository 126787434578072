import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbServiceService {
  private breadcrumbs: MenuItem[] = [];

  constructor(private router: Router) {
    // Load breadcrumbs from local storage when service is instantiated
    this.loadBreadcrumbs();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Generate and update the breadcrumb navigation for the current page
        this.createBreadcrumbs();

        // Save breadcrumbs to local storage
        this.saveBreadcrumbs();
      });
  }

  // Retrieves the breadcrumbs currently stored
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbs;
  }

  createBreadcrumbs(): MenuItem[] {
    let url = '';
    // Split the current URL into segments and filter out any empty segments
    const urlSegments = this.router.url.split('/').filter((segment) => segment);

    // Temporary array to store the new breadcrumb
    const newBreadcrumb: MenuItem[] = [];

    for (const segment of urlSegments) {
      // Append the current segment to the URL
      url += `/${segment}`;
      // Find the route configuration that matches the current segment
      const routeConfig = this.router.config.find((r) => r.path === segment);
      // Get the breadcrumb label from the route's data
      const label = routeConfig?.data?.['breadcrumb'];

      if (label) {
        // Check if the label already exists in the current breadcrumbs
        const existingIndex = this.breadcrumbs.findIndex(
          (breadcrumb) => breadcrumb.label === label
        );

        if (existingIndex !== -1) {
          // If the label exists, remove the breadcrumbs after this position
          this.breadcrumbs = this.breadcrumbs.slice(0, existingIndex + 1);
        } else {
          // If the label does not exist, add it to the new breadcrumb array
          newBreadcrumb.push({
            label,
            routerLink: url,
            isCurrent: this.router.url === url,
          });
        }
      }
    }

    // Update the breadcrumbs array to include the new breadcrumb
    this.breadcrumbs = [...this.breadcrumbs, ...newBreadcrumb];

    // Return the updated breadcrumbs array
    return this.breadcrumbs;
  }

  // This method loads breadcrumbs from the local storage.
  private loadBreadcrumbs(): void {
    // Retrieve stored breadcrumbs from local storage.
    const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
    // If there are stored breadcrumbs available, parse and assign them to the component's property.
    if (storedBreadcrumbs) {
      this.breadcrumbs = JSON.parse(storedBreadcrumbs);
    }
  }

  // This method saves breadcrumbs to the local storage.
  private saveBreadcrumbs(): void {
    // Convert the breadcrumbs array to a JSON string and store it in the local storage under the key 'breadcrumbs'.
    localStorage.setItem('breadcrumbs', JSON.stringify(this.breadcrumbs));
  }
}
